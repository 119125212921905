function GetQueryString(name) {
  var hash = window.location.hash || "";
  var queryStartIndex = hash.indexOf("?");
  if (queryStartIndex === -1) {
    return "";
  }
  var queryString = hash.substring(queryStartIndex + 1);
  var params = new URLSearchParams(queryString);
  var context = params.get(name);
  return context || "";
}

var authorizationStatus = false;
import { getRR, verifyRR, encrypt1, decrypt1 } from "@/utils/index";

//获取上级标识
var superiors_sign = GetQueryString("l");
//判断符合规则
if (decrypt1(superiors_sign) == false) {
  console.log("错误了");
  authorizationStatus = true;
}

if (!localStorage.getItem("l")) {
  localStorage.setItem("l", superiors_sign);
} else {
  console.log("已经存储了l");
}
if (decrypt1(superiors_sign) == false) {
  console.log("错误了");
  authorizationStatus = true;
} else {
  console.log("[ 进行下一步操作 ] >");
}
//判断本机标识
var cs = localStorage.getItem("cs") || encrypt1();
localStorage.setItem("cs", cs);
console.log("[ 判断本机标识 ] >");
if (decrypt1(cs) == false) {
  console.log("判断本机标识 错误了");
  authorizationStatus = true;
} else {
  console.log("判断本机标识 正确");
}

//获取固定标识
console.log("[ 获取固定标识 ] >", GetQueryString("i"));
let fixedKey = GetQueryString("i");
console.log("48", "main.js", fixedKey);
if (fixedKey) {
  console.log("[ 保存本地标识 ] >", fixedKey);
  localStorage.setItem("i", fixedKey);
} else {
  console.log("给他进入了错误页面");
  authorizationStatus = true;
}
window.location.href =
  window.location.origin +
  "/#/" +
  "?l=" +
  cs +
  "&I=" +
  encrypt1() +
  encrypt1() +
  "&L=" +
  encrypt1() +
  "&t=" +
  new Date().getTime() +
  "&i=" +
  fixedKey;
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/assets/tailwindcss.css";
import "@/assets/font.scss";
// 或者下面这种方式引入
import { encrypt, decrypt } from "@/utils/crypto";

console.log(encrypt);
console.log(encrypt("012345678901234567890123456789"));

Vue.config.productionTip = false;

// Vue.use(ElementUI);
import { Form, FormItem, Input, Button, Message } from "element-ui";

import "./registerServiceWorker";

Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Button);
Vue.prototype.$message = Message;
Vue.$getRR = getRR;
// 确保路由器已经初始化
router.onReady(() => {
  if ("serviceWorker" in navigator) {
    //判断是否开发环境
    if (process.env.NODE_ENV === "development") {
      return;
    }

    window.addEventListener("load", () => {
      navigator.serviceWorker.register("/service-worker.js");
      window.addEventListener("offline", () => {
        console.log("offline");
      });
      window.addEventListener("online", () => {
        console.log("online");
      });
    });
  }
  if (window.location.hash == "/404/") {
    return;
  }
  if (authorizationStatus) {
    window.location.href = window.location.origin + "/#/404/";
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
