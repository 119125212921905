import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
let requestList = new Set(); // 存储请求url

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url

  withCredentials: false, // 是否允许带cookie这些
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    if (store.getters.token) {
      config.headers["x-api-st"] = new Date().getTime();
      config.headers["lang-code"] = getlanguage(store.getters.language);
    }
    if (config.url.indexOf("upload" != -1)) {
      return config;
    }
    if (config.method === "post" || config.method === "put") {
      config.cancelToken = new axios.CancelToken((c) => {
        // 在这里阻止重复请求，上个请求未完成时，相同的请求不会再次执行
        let requestData = (config.data && JSON.stringify(config.data)) || {};
        requestList.has(`${process.env.VUE_APP_BASE_API}${config.url}"${requestData}"`)
          ? c(`${process.env.VUE_APP_BASE_API}${config.url}---重复请求被中断`)
          : requestList.add(`${process.env.VUE_APP_BASE_API}${config.url}"${JSON.stringify(config.data)}"`);
      });
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    let requestData = (response.config.data && JSON.stringify(response.config.data)) || {};

    try {
      requestList.delete(`${response.config.url}${requestData.replace(/\\/g, "")}`);
    } catch (error) {}
    const res = response.data;
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 20000) {
      Message({
        message: res.msg || "Error",
        type: "error",
        duration: 5 * 1000,
      });

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login

        store.dispatch("user/resetToken").then(() => {
          location.reload();
        });
      }

      return Promise.reject(new Error(res.msg || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    try {
      let requestData = (error.config.data && JSON.stringify(error.config.data).replace(/\\/g, "")) || {};
      requestList.delete(`${error.config.url}${requestData}`);
    } catch (error) {}
    if (axios.isCancel(error)) {
      Message({
        message: "已存在相同操作,等待上次操作挽回中,本次操作已被拦截",
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(new Error("请求重复"));
    } else {
      //判断是否请求超时
      if (error.code == "ECONNABORTED" && error.message.indexOf("timeout") != -1) {
        Message({
          message: "请求超时",
          type: "error",
          duration: 5 * 1000,
        });
        return Promise.reject(new Error("请求超时"));
      }
      if (error.response.status == 401) {
      }
      if (error.response.status == 412) {
        store.dispatch("user/resetToken").then(() => {});
        MessageBox.confirm(error.response.data.msg || "Error", {
          confirmButtonText: "去登陆",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          store.dispatch("user/logout").then(() => {
            location.reload();
          });
        });
        return Promise.reject(new Error(error.response.data.msg || "Error"));
      }
      if (error.response.status == 404) {
        return;
      }

      Message({
        message: error,
        type: "error",
        duration: 5 * 1000,
      });

      return Promise.reject(new Error("Error"));
    }
  }
);

export default service;
