//获取当前环境是否为生产环境
export default function getEnv() {
    if (process.env.NODE_ENV === 'development') {
        //开发环
        console.log('开发环境')
        let PUBLIC_KEY = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAg2bfC5vCTD4UBnxVn62y0h+
AVucb1IEwfBFYVptXzrYRTKdOeKc9kHN2SqWEgxX28OCZgEcCbiSU77YpgWV2Azc
86XEqysRq7+HXdB/BnHS25ZwaJF+s4yQ0oyBzN6pv6jZO1jUAgTrkmoJoH3OMiWd
Ox0+f5TocsmaDb+JekvFbBwododyAw6Rw+uLjVmd8yuezCo8jb4+dr7AaQZRdGTM
eCVg4hflUbS6viJ5BHRTV6GLZHEx0WFQiIN5aFqi0kMPdl3gEPNfToicvQhiBDpS
LHSEOSnHJ+/4UG0LHMnXEeB+uAlTN2tL3plIIvyob33o+TU4ppXqBGp2O0vnPnwI
DAQAB`;
        //私钥
        let PRIVATE_KEY = `MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCDZt8Lm8JMPhQGf
FWfrbLSH4BW5xvUgTB8EVhWm1fOthFMp054pz2Qc3ZKpYSDFfbw4JmARwJuJJTvt
imBZXYDNzzpcSrKxGrv4dd0H8GcdLblnBokX6zjJDSjIHM3qm/qNk7WNQCBOuSag
mgfc4yJZ07HT5/lOhyyZoNv4l6S8VsHCh2h3IDDpHD64uNWZ3zK57MKjyNvj52vs
BpBlF0ZMx4JWDiF+VRtLq+InkEdFNXoYtkcTHRYVCIg3loWqLSQw92XeAQ819OiJ
y9CGIEOlIsdIQ5Kccn7/hQbQscydcR4H64CVM3a0vemUgi/Khvfej5NTimleoEan
Y7S+c+fAgMBAAECggEAXEOiP6NppWE9bGRnNsyfMB1AX0S9bNQdeDAxp/ZBjHqY4
Qrk9aDwgHoiSD26MtT8ZJxADCtQEIiNL1foAtx8JbvmgD+e/Ws3byJTx0z5BGOLf
OPEE2A+o+rrLtN9M0vwczMY3h2vMg5pL/h7JgJbXqt0TEyjiC/qsHV8utXm9COHG
92qtbHfdfuLaQ7c0BZ3z8uOF2XphJ9x0aUT7Y7xWpemGSGRHXBaA8egSglvDgmYU
3A3j4w+aJK9TFiipiUQXlw1cCyzMzaYVjPq5S96MerrmyXN18OsUNLxhooMhL1nM
RvX2WEr614LPbwLurTAeEKxF0wgR70/L8OF6nukIQKBgQD+De91PaNoJ5UROVS6U
gWt3x3i3SNSFK0cFbo3q2EUYlZ76H7hIpiVhQgfUYU0Zr7g7d7RQPiS93cubKAHX
x48eQ/zHE6roulKTPGTl7R1g/GWm7Otzay03Dj0X9aCQjNFJpDKQSEclSYTGZAFx
8q8mTVbu5XHCyDeYRei6WF+DwKBgQCEaHrY4jEoGSc84gDzgsrO1g0Psqc+c4Hu0
SAYXinPepoG/Voxpc4WTxZ61+tUwhTghzjFsgRHAGzEFqf5H5tjrz7j8TdyVkM7h
xCmxOLVPi5uiHwQTtT7STg9kfrQWNHfG5nSTrCPwEmjFAZHti+x5JcXBM3d7Bp6P
GdXtbclcQKBgCwNdow51+/jjTGjY1G7j0iK+7+u4MUG2M2Z5uRoTzkdpClwMzAOs
66ZlsL3e8WOW2LdXFmkjnXNe1kNdOJSWAfcZiudqPaSFSnRFcKl96C49FdNQGbO1
ze1SBr301Za7FD9UvX/PSKbqjJ9qD+YWUtCtcHmUR5mi5iD9XlGRqV5AoGABEqOk
LDj/vSxvNtmRH4crI9rZPLZUMDVmTPV4nRKzpqem1NitPE2uKpfhCOzUM/pdU1sn
ZujX9m3rLM/Rmer1ZM8ao7KyT3ad/dsprsCzv5w6swpmS204WACHpy4kEAQrTZry
dEbD82flVV0zqYs7YsHCovxUBvyz7tdbg5JnUECgYB+/MkgfeBid6IAXps0x2odM
D5ShnuwPOORAn91hGIWe1JQiJ+frrb9vTvlhWOrwyPLZJ1hgurhFfYWT5iROG33o
aeuD5XV2/cjooPTlW7HSkmV7qHFhjO9n7iIp7CxKgTzTGev8fJ1Ypwv+hF0UQAek
+xOD57A0aZQmjV2kA4LOw==`;
        return {
            PUBLIC_KEY, PRIVATE_KEY
        }
    } else {
        //生产环境
        console.log('生产环境')
        //公钥

        let PUBLIC_KEY = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0p6df6KknZCAn9hvmkWV
        5QNtnEIo5VVtgGCRexC8Y4BVlNNLFA562Ay6zBel2xWwACwSOWcxH0rqwxQBmJop
        PprHmzIqZc8s9zIrdT6MCIJIuIAhQt2XWOMvWUAu7/Sr7Y+49wtsUQ69Z4uAaAsM
        E8VSgNQMrWFRd2eGUFZnwQ0gTCuhwrbue4JvgRO4w4fL2XopT+7RHGAEuI4DJKDe
        O9VGP5gbjrgXwM0qi9y0pwMjNm78BxDexlR5FiW7zDmBoXQZREQKDFDEKE8qshGT
        oS8SiAnps7iGvWxz2oGrKOxFlEChREAK3ZScQgb3x/exZVkqKla3fIjlXYH503Vh
        3QIDAQAB`;
        //私钥
        let PRIVATE_KEY = `MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCDZt8Lm8JMPhQG
fFWfrbLSH4BW5xvUgTB8EVhWm1fOthFMp054pz2Qc3ZKpYSDFfbw4JmARwJuJJT
vtimBZXYDNzzpcSrKxGrv4dd0H8GcdLblnBokX6zjJDSjIHM3qm/qNk7WNQCBOuS
agmgfc4yJZ07HT5/lOhyyZoNv4l6S8VsHCh2h3IDDpHD64uNWZ3zK57MKjyNvj5
2vsBpBlF0ZMx4JWDiF+VRtLq+InkEdFNXoYtkcTHRYVCIg3loWqLSQw92XeAQ819
OiJy9CGIEOlIsdIQ5Kccn7/hQbQscydcR4H64CVM3a0vemUgi/Khvfej5NTimleo
EanY7S+c+fAgMBAAECggEAXEOiP6NppWE9bGRnNsyfMB1AX0S9bNQdeDAxp/ZBjH
qY4Qrk9aDwgHoiSD26MtT8ZJxADCtQEIiNL1foAtx8JbvmgD+e/Ws3byJTx0z5BG
OLfOPEE2A+o+rrLtN9M0vwczMY3h2vMg5pL/h7JgJbXqt0TEyjiC/qsHV8utXm9C
OHG92qtbHfdfuLaQ7c0BZ3z8uOF2XphJ9x0aUT7Y7xWpemGSGRHXBaA8egSglvDg
mYU3A3j4w+aJK9TFiipiUQXlw1cCyzMzaYVjPq5S96MerrmyXN18OsUNLxhooMhL
1nMRvX2WEr614LPbwLurTAeEKxF0wgR70/L8OF6nukIQKBgQD+De91PaNoJ5UROV
S6UgWt3x3i3SNSFK0cFbo3q2EUYlZ76H7hIpiVhQgfUYU0Zr7g7d7RQPiS93cubK
AHXx48eQ/zHE6roulKTPGTl7R1g/GWm7Otzay03Dj0X9aCQjNFJpDKQSEclSYTGZ
AFx8q8mTVbu5XHCyDeYRei6WF+DwKBgQCEaHrY4jEoGSc84gDzgsrO1g0Psqc+c4
Hu0SAYXinPepoG/Voxpc4WTxZ61+tUwhTghzjFsgRHAGzEFqf5H5tjrz7j8TdyVk
M7hxCmxOLVPi5uiHwQTtT7STg9kfrQWNHfG5nSTrCPwEmjFAZHti+x5JcXBM3d7B
p6PGdXtbclcQKBgCwNdow51+/jjTGjY1G7j0iK+7+u4MUG2M2Z5uRoTzkdpClwMzA
Os66ZlsL3e8WOW2LdXFmkjnXNe1kNdOJSWAfcZiudqPaSFSnRFcKl96C49FdNQGb
O1ze1SBr301Za7FD9UvX/PSKbqjJ9qD+YWUtCtcHmUR5mi5iD9XlGRqV5AoGABEq
OkLDj/vSxvNtmRH4crI9rZPLZUMDVmTPV4nRKzpqem1NitPE2uKpfhCOzUM/pdU1
snZujX9m3rLM/Rmer1ZM8ao7KyT3ad/dsprsCzv5w6swpmS204WACHpy4kEAQrTZ
rydEbD82flVV0zqYs7YsHCovxUBvyz7tdbg5JnUECgYB+/MkgfeBid6IAXps0x2o
dMD5ShnuwPOORAn91hGIWe1JQiJ+frrb9vTvlhWOrwyPLZJ1hgurhFfYWT5iROG3
3oaeuD5XV2/cjooPTlW7HSkmV7qHFhjO9n7iIp7CxKgTzTGev8fJ1Ypwv+hF0UQA
ek+xOD57A0aZQmjV2kA4LOw==`;
        return {
            PUBLIC_KEY, PRIVATE_KEY
        }

    }
}