import request from "@/utils/request";
export function set_organizer(data) {
  return request({
    url: "/loanapi/admin_account/set_organizer",
    method: "post",
    data,
  });
}

export function get_system_name(data) {
  return request({
    url: "/loanapi/open/get_system_name",
    method: "get",
    params: data,
  });
}
export function submit_phone_info(data) {
  return request({
    url: "/loanapi/web_promotion_api/submit_phone_info",
    method: "post",
    data,
  });
}


export function get_channel_info(data) {
  return request({
    url: "/loanapi/webapi_v2/get_channel_info",
    method: "post",
    data,
  });
}
 
export default {
  set_organizer,
  get_system_name, submit_phone_info,get_channel_info
};
