import JSEncrypt from "./jsencrypt";
import getEnv from './JSEncryptKey'

let {PUBLIC_KEY, PRIVATE_KEY} = getEnv()
export function encrypt(params) {
    let encrypt = new JSEncrypt.JSEncrypt();
    encrypt.setPublicKey("-----BEGIN PUBLIC KEY-----" + PUBLIC_KEY + "-----END PUBLIC KEY-----");

    // 判断是否是对象格式
    if (typeof params === "object") {
        params = JSON.stringify(params);
    }

    return encrypt.encrypt(params);
}

export function decrypt(params) {
    let decrypt = new JSEncrypt.JSEncrypt();
    decrypt.setPrivateKey("-----BEGIN RSA PRIVATE KEY-----" + PRIVATE_KEY + "-----END RSA PRIVATE KEY-----");
    let uncrypted = decrypt.decrypt(params);
    console.log("解密后数据:%o", uncrypted);
    return uncrypted;
}

export function decrypt_Public(params) {
    let decrypt = new JSEncrypt.JSEncrypt();
    decrypt.setPublicKey("-----BEGIN RSA PUBLIC KEY-----" + PUBLIC_KEY + "-----END RSA PUBLIC KEY-----");
    console.log('[ PUBLIC_KEY ] >', PUBLIC_KEY)
    let uncrypted = decrypt.decrypt_Public(params);
    console.log("解密后数据:%o", uncrypted);
    return uncrypted;
}
