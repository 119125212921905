import md5 from "js-md5";

function _0x3238(_0x197449, _0x1e882b) {
  var _0x11de42 = _0x11de();
  return (
    (_0x3238 = function (_0x3238e9, _0x228ae4) {
      _0x3238e9 = _0x3238e9 - 0x191;
      var _0x3a1776 = _0x11de42[_0x3238e9];
      return _0x3a1776;
    }),
    _0x3238(_0x197449, _0x1e882b)
  );
}
(function (_0x10bbee, _0x2edb3c) {
  var _0x1ec2c1 = _0x3238,
    _0xb7c331 = _0x3238,
    _0x9c249f = _0x3238,
    _0x4fd87f = _0x3238,
    _0x21e0fa = _0x3238,
    _0x277201 = _0x3238,
    _0x523bf0 = _0x3238,
    _0x174a37 = _0x3238,
    _0x54b6b3 = _0x3238,
    _0x4473d6 = _0x10bbee();
  while (!![]) {
    try {
      var _0x162da9 =
        (-parseInt(_0x1ec2c1(0x19e)) / 0x1) * (parseInt(_0xb7c331(0x197)) / 0x2) +
        parseInt(_0x1ec2c1(0x198)) / 0x3 +
        (parseInt(_0x1ec2c1(0x192)) / 0x4) * (-parseInt(_0x9c249f(0x195)) / 0x5) +
        -parseInt(_0x277201(0x193)) / 0x6 +
        parseInt(_0x523bf0(0x194)) / 0x7 +
        -parseInt(_0x174a37(0x1a1)) / 0x8 +
        parseInt(_0x1ec2c1(0x196)) / 0x9;
      if (_0x162da9 === _0x2edb3c) break;
      else _0x4473d6["push"](_0x4473d6["shift"]());
    } catch (_0xe423f) {
      _0x4473d6["push"](_0x4473d6["shift"]());
    }
  }
})(_0x11de, 0x3fa66);
export function getRR() {
  var _0x1d2cc4 = _0x3238,
    _0x2b3440 = _0x3238,
    _0xf7e159 = _0x3238,
    _0xd49ed1 = _0x3238;
  function _0x2ff1be(_0x3b1e6f) {
    var _0x24aa30 = _0x3238,
      _0x2fcc13 = _0x3238,
      _0x5f2c17 = _0x3238,
      _0x111d82 = _0x3238,
      _0x27858d = _0x3238;
    _0x3b1e6f = _0x3b1e6f || 0x12;
    var _0xb751d6 = _0x24aa30(0x19b),
      _0x51a4ee = _0xb751d6[_0x2fcc13(0x19a)],
      _0x4517b6 = "";
    for (let _0x327b58 = 0x0; _0x327b58 < _0x3b1e6f; _0x327b58++) {
      _0x4517b6 += _0xb751d6[_0x5f2c17(0x191)](Math[_0x24aa30(0x19d)](Math[_0x111d82(0x19c)]() * _0x51a4ee));
    }
    return _0x4517b6;
  }
  let _0x107b4d = _0x2ff1be(0x12),
    _0x3ef5f7 =
      md5(_0x107b4d)[_0x1d2cc4(0x199)](0x0, 0xe)[_0x2b3440(0x1a0)]("")[_0x1d2cc4(0x19f)]()[_0x1d2cc4(0x1a2)]("") +
      _0x107b4d;
  return _0x3ef5f7;
}
function _0x11de() {
  var _0x333f41 = [
    "8856tPrVTV",
    "substring",
    "length",
    "abcdefghijklmnopqrstuvwxyz0123456789",
    "random",
    "floor",
    "68xJTpCL",
    "reverse",
    "split",
    "3763160qFUgdX",
    "join",
    "charAt",
    "1865132GlWSVb",
    "1080114kKkBaD",
    "2804872oGJHIU",
    "5HurbNf",
    "13115151uDlQmA",
    "14220CniNOx",
  ];
  _0x11de = function () {
    return _0x333f41;
  };
  return _0x11de();
}

(function (_0x2a59bd, _0x1ee960) {
  const _0x56ad04 = _0x5ea5,
    _0x42ce91 = _0x5ea5,
    _0x402824 = _0x5ea5,
    _0x3222a6 = _0x5ea5,
    _0x130cb8 = _0x5ea5,
    _0xf75851 = _0x5ea5,
    _0x117f66 = _0x5ea5,
    _0x5eb454 = _0x5ea5,
    _0x51c53a = _0x5ea5,
    _0x101226 = _0x5ea5,
    _0xf0a656 = _0x5ea5,
    _0x2f71d4 = _0x2a59bd();
  while (!![]) {
    try {
      const _0x512983 =
        (parseInt(_0x56ad04(0x1d6)) / 0x1) * (parseInt(_0x56ad04(0x1d7)) / 0x2) +
        (parseInt(_0x402824(0x1d8)) / 0x3) * (-parseInt(_0x56ad04(0x1dc)) / 0x4) +
        -parseInt(_0x402824(0x1ce)) / 0x5 +
        (-parseInt(_0x3222a6(0x1da)) / 0x6) * (-parseInt(_0x130cb8(0x1d0)) / 0x7) +
        (parseInt(_0x3222a6(0x1db)) / 0x8) * (parseInt(_0x3222a6(0x1d9)) / 0x9) +
        -parseInt(_0x130cb8(0x1d2)) / 0xa +
        parseInt(_0xf0a656(0x1d5)) / 0xb;
      if (_0x512983 === _0x1ee960) break;
      else _0x2f71d4["push"](_0x2f71d4["shift"]());
    } catch (_0x5d8212) {
      _0x2f71d4["push"](_0x2f71d4["shift"]());
    }
  }
})(_0x1aff, 0xd241e);
export function verifyRR(_0xe62f84) {
  const _0x38a2c7 = _0x5ea5,
    _0x357267 = _0x5ea5,
    _0xf7a168 = _0x5ea5,
    _0x39206d = _0x5ea5,
    _0x5bfcc8 = _0x5ea5,
    _0x2a8f77 = _0x5ea5;
  let _0x2fb579 = _0xe62f84[_0x38a2c7(0x1d4)](0x0, 0xe),
    _0x271a0a = _0xe62f84[_0x357267(0x1d4)](0xe),
    _0x1b1592 = _0x2fb579[_0xf7a168(0x1d3)]("")[_0xf7a168(0x1d1)]()[_0x5bfcc8(0x1cf)](""),
    _0x4a043b = md5(_0x271a0a)[_0x38a2c7(0x1d4)](0x0, 0xe);
  return _0x1b1592 === _0x4a043b;
}
function _0x5ea5(_0x286443, _0x15cd20) {
  const _0x1aff95 = _0x1aff();
  return (
    (_0x5ea5 = function (_0x5ea5ad, _0x13c8c5) {
      _0x5ea5ad = _0x5ea5ad - 0x1ce;
      let _0x3afbd8 = _0x1aff95[_0x5ea5ad];
      return _0x3afbd8;
    }),
    _0x5ea5(_0x286443, _0x15cd20)
  );
}
function _0x1aff() {
  const _0x54e84b = [
    "207844dJgkRe",
    "reverse",
    "15744650puzDDZ",
    "split",
    "substring",
    "3134142gddjNB",
    "855953BdXyAH",
    "4whkoDC",
    "3hhgcjY",
    "9zLYMgV",
    "234ybpObG",
    "9574008fSQccp",
    "1181788tmyuYT",
    "8102205DpGpnU",
    "join",
  ];
  _0x1aff = function () {
    return _0x54e84b;
  };
  return _0x1aff();
}








const crypto = require('crypto');

// 生成随机的18位字符串
function generateRandomString(length) {
    return crypto.randomBytes(length).toString('hex').slice(0, length);
}

// 生成校验码
function generateChecksum(data) {
    const hash = crypto.createHash('md5').update(data).digest('hex');
    return hash.slice(0, 14).split('').reverse().join('');
}

// 加密函数
export function encrypt1() {
    const randomCode = generateRandomString(18);
    const checksum = generateChecksum(randomCode);
    return randomCode + checksum;
}

// 解密函数（这里的“解密”只是验证校验码是否正确）
export function decrypt1(code) {
    const randomCode = code.slice(0, 18);
    const checksum = code.slice(18);
    const validChecksum = generateChecksum(randomCode);

    if (checksum === validChecksum) {
        return true; // 校验码匹配
    } else {
        return false; // 校验码不匹配
    }
}
