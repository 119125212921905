<template>
  <div v-if="lod">
    <load></load>
  </div>
  <div v-else id="app">
    <noscript>
      <img v-if="faceBookCode" height="1" width="1" :src="imgurl" />
    </noscript>
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
            <router-link to="/about">About</router-link> -->
    </div>
    <router-view @click="download_url" />
  </div>
</template>
<script>
import load from "./views/load.vue";
import { encrypt, decrypt_Public } from "@/utils/crypto";
import { get_channel_info } from "@/api/form";

export default {
  name: "App",
  components: {
    load,
  },
  data() {
    return {
      apk_download_url: "",
      faceBookCode:false,
      lod: true,
    };
  },
  // created() {
  //   // 检查浏览器支持
  //   if ("Notification" in window) {
  //     // 请求用户权限
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         // 用户授权，发送通知
  //         navigator.serviceWorker.getRegistration().then((registration) => {
  //           if (registration) {
  //           registration.showNotification(msg);
  //           }
  //         });
  //         const notification = new Notification("Hello, World!", {
  //           body: "这是通知的正文内容。",
  //           icon: "icon.png",
  //         });
  //       } else {
  //         // 用户没有授权或拒绝了通知
  //         console.log("用户没有授权通知。");
  //       }
  //     });
  //   }
  // },
  mounted() {
    get_channel_info({
      data:
        encrypt({
          superiors_sign: localStorage.getItem("l"),
          customer_sign: localStorage.getItem("cs"),
          timestamp: new Date().getTime(),
        }) + localStorage.getItem("i"),
    }).then((res) => {
      let str = "";
      res.data.data.forEach((element) => {
        str += decrypt_Public(element);
      });
      this.lod = false;
      let data = JSON.parse(str);
      console.log("%c 62", "font-size:13px; background:#60fe74; color:#a4ffb8;", data);
      window.apk_download_url = data.apk_download_url;
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

      // 现在data是解密后的对象信息
      if (data.google_id) {
        const gtagId = data.google_id;
        window.send_to = data.send_to;
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];

        function gtag() {
          dataLayer.push(arguments);
        }

        gtag("js", new Date());
        gtag("config", gtagId);
        window.gtag = gtag;
      }

      if (data.facebook_point) {
        this.faceBookCode=true
      let faceBookCode = data.facebook_point;
        this.imgurl = "https://www.facebook.com/tr?id=" + faceBookCode + "&ev=PageView&noscript=1";
        !(function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        try {
          fbq("init", faceBookCode);
          // fbq("track", "PageView");
        } catch (error) {}
      }
      if (isiOS) {
        console.log("[ ios ] ");
        // 清除历史记录
        window.history.pushState(null, null, document.URL);
        //跳转 data.h5_link
        this.lod = true;
        try {
          fbq("track", "IOS");
        } catch (error) {}
        setTimeout(() => {
          this.lod = false;
          window.location.href = data.h5_link;
        }, 100);
      }
      if (data.google_id.length > 4) {
        try {
          const gtagId = data.google_id;
          const script = document.createElement("script");
          script.async = true;
          script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;
          document.body.appendChild(script);
          window.send_to = data.google_event_name;
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }

          gtag("js", new Date());
          gtag("config", gtagId);
          gtag("event", "init", { method: "Google" });
          window.gtag = gtag;
        } catch (error) {
          console.log("google_id", error);
        }
      }
    });
  },
  methods: {
    //下载apk
    download_url() {
      console.log("[ 123 ] >", 123);
      // 使用a标签下载
      let a = document.createElement("a");
      a.href = window.apk_download_url;
      a.download = "app.apk";
      a.click();
    },
    xiazia() {
      window.gtag("event", "conversion", {
        send_to: window.send_to,
        event_callback: callback,
      });
    },
  },
};
</script>
<style lang="scss"></style>
